#navbarToggler01
{
    display: flex;
    justify-content:flex-end;
    font-size: 20px;
    flex-wrap: wrap; 
    /* background-color: aqua; */
}
#Image1
{
    margin-top: 0.8em;
}
#nav_container
{
    background-color: transparent;
}