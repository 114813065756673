.button,.phoneno
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_container
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}